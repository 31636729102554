import React from "react";
import styles from "./Organisation.module.css";

const AjmerDivision = () => {
  return (
    <div className={styles.TableContainer}>
      <table className={styles.membersTable}>
        <thead>
          <tr>
            <th>Unit</th>
            <th>Chairman</th>
            <th>Secretary</th>
            <th>Vice Chairman</th>
            <th>Vice Chairman</th>
            <th>Vice Chairman</th>
            <th>Asstt. Secretary</th>
            <th>Asstt. Secretary</th>
            <th>Asstt. Secretary</th>
            <th>Asstt. Secretary</th>
            <th>Treasurer</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Ajmer Division</td>
            <td>Rajeev Sharma</td>
            <td>Mohan Das Chelani</td>
            <td>Chetram Meena</td>
            <td>Santosh Kumar Sharma</td>
            <td>Shishupal Ojha</td>
            <td>Baldev Singh</td>
            <td>L.N. Meena</td>
            <td>N. L. Vijay</td>
            <td>Madhu Jindal</td>
            <td>Balmukund Sain</td>
          </tr>
          <tr>
            <td></td>
            <td>9828180069</td>
            <td>9001196617</td>
            <td>9694968669</td>
            <td>9414610736</td>
            <td>9377200466</td>
            <td>9414556201</td>
            <td>9680667704</td>
            <td>9001917894</td>
            <td></td>
            <td>9001196447</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Ajmer Engineering</td>
            <td>Mahesh Singh</td>
            <td>Baldev Singh</td>
            <td>Kundanmal</td>
            <td>Sanjay Singhal</td>
            <td>Virendra Kumar Taak</td>
            <td>Bharat Singh</td>
            <td>Ranjeet Singh</td>
            <td>Sandeep Joshi</td>
            <td>Shaitan Singh</td>
            <td>Neeraj Kansal</td>
          </tr>
          <tr>
            <td></td>
            <td>9799114259</td>
            <td>9414556201</td>
            <td>9928027260</td>
            <td>9001196027</td>
            <td>9828170211</td>
            <td>9460331679</td>
            <td>9694142404</td>
            <td>9001195241</td>
            <td>9001196027</td>
            <td>9001196023</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Ajmer GLO</td>
            <td>Ramesh Nimediya</td>
            <td>L N Meena</td>
            <td>Anil Gupta</td>
            <td>Arvind Yadav</td>
            <td>Damodar Kumar</td>
            <td>Jitendra Sharma</td>
            <td>Neha Gurjar</td>
            <td>Piyush Mathur</td>
            <td>Sunil Sharma</td>
            <td>Veeresh Sharma</td>
          </tr>
          <tr>
            <td></td>
            <td>9414057758</td>
            <td>9680667704</td>
            <td>9001196811</td>
            <td>9829272656</td>
            <td>9829952725</td>
            <td>9829797568</td>
            <td></td>
            <td>9251000952</td>
            <td>9352004591</td>
            <td>9928080621</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Ajmer GLO (Ac)</td>
            <td>Atul Kumar Vishva</td>
            <td>Sanjay Chaturvedi</td>
            <td>Hemraj Sani</td>
            <td>Surendra K Saini</td>
            <td>Vijay Lodha</td>
            <td>Dalchand Sawasiya</td>
            <td>Lokesh Verma</td>
            <td>Umesh Mathur</td>
            <td>Yogesg Pd. Gupta</td>
            <td>Yogesh Jha</td>
          </tr>
          <tr>
            <td></td>
            <td>9352465632</td>
            <td>9001194501</td>
            <td>9001196165</td>
            <td>9001634204</td>
            <td>9001196126</td>
            <td>9667347099</td>
            <td>9549831430</td>
            <td>9024812349</td>
            <td>9001196114</td>
            <td>8290377081</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Ajmer Local</td>
            <td>Rajeev Sharma</td>
            <td>Gaurav Mehara</td>
            <td>Arvind Ken</td>
            <td>Irfan Khan</td>
            <td>Nirmal Singh Rathore</td>
            <td>Kailash Rawat</td>
            <td>Nand Kishore</td>
            <td>Ram Prasad Bairwa</td>
            <td>Bhuvnesh Kumavat</td>
            <td>Ashish Mehta</td>
          </tr>
          <tr>
            <td></td>
            <td>9828180069</td>
            <td>9251499090</td>
            <td>9571963197</td>
            <td>9785203097</td>
            <td>9887755543</td>
            <td>7976230869</td>
            <td>9413300642</td>
            <td>9799866279</td>
            <td>9251000654</td>
            <td>9530060025</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Ajmer Medical</td>
            <td>Sanjeev Arora</td>
            <td>Sanjay Kumar</td>
            <td>Mahendra Kumar Nama</td>
            <td>Bimla Devi</td>
            <td>Pradeep Kumar</td>
            <td>Manish Pareek</td>
            <td>Hemant Kumar</td>
            <td>Sangeeta Lal</td>
            <td>Sohan Lal</td>
            <td>Manoj Sinha</td>
          </tr>
          <tr>
            <td></td>
            <td>9116012035</td>
            <td>9785847359</td>
            <td>9950905220</td>
            <td></td>
            <td>9992400056</td>
            <td>9928587575</td>
            <td>8078618306</td>
            <td></td>
            <td>7259631922</td>
            <td>9001196553</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Ajmer Station</td>
            <td>Dinesh Mehariya</td>
            <td>Rajeev Sain</td>
            <td>Chetram Verma</td>
            <td>Deepti Sharma</td>
            <td>Arjun Singh</td>
            <td>Anwar Ali</td>
            <td>Ravindra Choudhry</td>
            <td>Dharmendra Choudhary</td>
            <td>Rakesh Solanki</td>
            <td>Gaurav Sain</td>
          </tr>
          <tr>
            <td></td>
            <td>9001815443</td>
            <td>9983348233</td>
            <td>9799970730</td>
            <td></td>
            <td>9414730864</td>
            <td>7877180330</td>
            <td>9511551437</td>
            <td>9667463962</td>
            <td>9983348957</td>
            <td>9001194883</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Ajmer Store</td>
            <td>Anil Taneja</td>
            <td>Vipul Saxena</td>
            <td>Kamal Kumar</td>
            <td>Narendra Gautam</td>
            <td>Nirmala Kumari</td>
            <td>Chandra Prakash Sharma</td>
            <td>Narendra Sharma</td>
            <td>Shivraj</td>
            <td>Virendra Kumar Sharma</td>
            <td>Devendra Singh</td>
          </tr>
          <tr>
            <td></td>
            <td>9414556594</td>
            <td>9414347546</td>
            <td>9829310830</td>
            <td>9460611621</td>
            <td>9928728486</td>
            <td>9414364077</td>
            <td>9950723174</td>
            <td>9983344792</td>
            <td>8209730106</td>
            <td>9001196779</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Abu Road-1</td>
            <td>Devendra Sharma</td>
            <td>Samander Singh Rathor</td>
            <td>Abhay Sharma</td>
            <td>Mangu Singh Rawat</td>
            <td>Tarun Poddar</td>
            <td>Ravi Kumar</td>
            <td>Sanjay Saxena</td>
            <td>Shailendra Kumawat</td>
            <td>Vikas Morwal</td>
            <td>Chandra Shekhar N</td>
          </tr>
          <tr>
            <td></td>
            <td>9001194132</td>
            <td>9001166188</td>
            <td>9610754825</td>
            <td>9001031580</td>
            <td>7976266788</td>
            <td>8107952477</td>
            <td>8005628713</td>
            <td>9116680890</td>
            <td>9772575438</td>
            <td>9001031645</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Abu Road-2 (D.Shed)</td>
            <td>Hitesh Sharma</td>
            <td>Ajay Kumar Sharma</td>
            <td>Babu Lal Parihar</td>
            <td>Gopal Balai</td>
            <td>Mehtab Khan</td>
            <td>Anil Kumar Singh</td>
            <td>Jitendra Sharma</td>
            <td>Maan Singh Gurjar</td>
            <td>Omaram Choyal</td>
            <td>Rajendra Prasad Sharma</td>
          </tr>
          <tr>
            <td></td>
            <td>9829123045</td>
            <td>9461444565</td>
            <td>9982029020</td>
            <td>9636924956</td>
            <td>9828709456</td>
            <td>6376943018</td>
            <td>9001196568</td>
            <td>9116029944</td>
            <td>9785734685</td>
            <td>9829774062</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Beawar</td>
            <td>Ashok Aadra</td>
            <td>Pappu Singh</td>
            <td>Balendra Singh Rajpoot</td>
            <td>Neeraj Singh</td>
            <td>S L Bhakar</td>
            <td>Bharat Singh</td>
            <td>Dhirendra Kumar</td>
            <td>Hemraj</td>
            <td>Jeewan Singh</td>
            <td>Aayush Mathur</td>
          </tr>
          <tr>
            <td></td>
            <td>9079612117</td>
            <td>9785100105</td>
            <td>9981667908</td>
            <td>9928377673</td>
            <td>9001196077</td>
            <td>9460304723</td>
            <td>9783040807</td>
            <td>9587257547</td>
            <td>9001342823</td>
            <td>8290453132</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Bhilwara</td>
            <td>Rameshwar Prasad Meena</td>
            <td>Kailash Chand Suwalka</td>
            <td>Gordhan Ram</td>
            <td>Santosh Kumar Meena</td>
            <td>Shyam Lal Yadav</td>
            <td>Bharat Lal Aheer</td>
            <td>Radheyshyam Luhar</td>
            <td>Shailendra Mathur</td>
            <td>Subhash Dikshit</td>
            <td>Kashmeer Mohammad</td>
          </tr>
          <tr>
            <td></td>
            <td>9887007007</td>
            <td>9166120999</td>
            <td>9001196969</td>
            <td>9001196848</td>
            <td>9461711845</td>
            <td>9252035155</td>
            <td>9887935591</td>
            <td>9414372317</td>
            <td>9413271562</td>
            <td>9602424308</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Dungarpur</td>
            <td>Ramesh Chand Ambesh</td>
            <td>Ashok Kumar</td>
            <td>Akash Bheemwal</td>
            <td>Jaheed Lai</td>
            <td>Karan Telar</td>
            <td>Navnihal Singh</td>
            <td>Pradyuman Singh</td>
            <td>Rakesh Kumar Yadav</td>
            <td>Satish Patel</td>
            <td>Yogendra Kumar</td>
          </tr>
          <tr>
            <td></td>
            <td>9001196096</td>
            <td>9116009750</td>
            <td>9001196072</td>
            <td>9256119047</td>
            <td>9256117995</td>
            <td>8824900275</td>
            <td>8690398811</td>
            <td>8426907670</td>
            <td>9414267431</td>
            <td>9414723172</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Khamlighat</td>
            <td>Arvind Kumar</td>
            <td>Vijaypaal Vishnoi</td>
            <td>Dewaram</td>
            <td>Madan Singh</td>
            <td>Sureshchandra</td>
            <td>Jitendra</td>
            <td>Laxman Lal</td>
            <td>Nain Singh</td>
            <td>Naresh Gurjar</td>
            <td>Kedar Das</td>
          </tr>
          <tr>
            <td></td>
            <td>9785620614</td>
            <td>9772070519</td>
            <td>9828334255</td>
            <td>8239199875</td>
            <td>9929427590</td>
            <td>9079649880</td>
            <td>6350177698</td>
            <td>9610618554</td>
            <td>7976389530</td>
            <td>9610618554</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Marwar Jn.</td>
            <td>Sohan Lal Kumawat</td>
            <td>Nawal Kishor</td>
            <td>Dinesh Kumar Nagasiya</td>
            <td>Jay Gurjar</td>
            <td>Nemi Chand Jangir</td>
            <td>Om Prakash M</td>
            <td>Ashok Kumar Sharma</td>
            <td>Jamna Lal Regar</td>
            <td>Kuldeep Sharma</td>
            <td>Ghisu Lal M</td>
          </tr>
          <tr>
            <td></td>
            <td>976029266</td>
            <td>9001196879</td>
            <td>9558550822</td>
            <td>7232834545</td>
            <td>9001196912</td>
            <td>9116680915</td>
            <td>9001196965</td>
            <td>8741003486</td>
            <td>9887671161</td>
            <td>9950334149</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Mavli Jn.</td>
            <td>Devendra Kumar Yogi</td>
            <td>Ramji Lal Saini</td>
            <td>Bajrang Lal Kumawat</td>
            <td>Devendra Kumar Khairiya</td>
            <td>Dharmesh Kumar Tripathi</td>
            <td>Kailash Dangi</td>
            <td>Rahul Sharma</td>
            <td>Sumit Kumar Khatana</td>
            <td>Uday Lal Meena</td>
            <td>Ameer Mohammad</td>
          </tr>
          <tr>
            <td></td>
            <td>7891141551</td>
            <td>9001901288</td>
            <td>9460662001</td>
            <td>7665447676</td>
            <td>9001196069</td>
            <td>9602623854</td>
            <td>9602644074</td>
            <td>9602822040</td>
            <td>8005829799</td>
            <td>9413217895</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Nasirabad</td>
            <td>K L Meena</td>
            <td>Santosh Kumar Sharma</td>
            <td>Bhanu Sharma</td>
            <td>Rakesh Sharma</td>
            <td>Rohitashw Kumar</td>
            <td>Deepesh Bachnani</td>
            <td>Devi Singh</td>
            <td>Lokesh Mehra</td>
            <td>Mukesh Upadhyay</td>
            <td>O P Meena</td>
          </tr>
          <tr>
            <td></td>
            <td>9001196064</td>
            <td>9414610736</td>
            <td>9414462037</td>
            <td>9116680933</td>
            <td>9782088846</td>
            <td>9001196095</td>
            <td>7737541028</td>
            <td>9256118064</td>
            <td>9680591009</td>
            <td>9460861548</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Rani-Falna</td>
            <td>Manaram Dewasi</td>
            <td>Mauji Ram Meena</td>
            <td>Chetram Meena</td>
            <td>Krishna Saini</td>
            <td>Ranvijay Kumar</td>
            <td>Mahesh Kumar</td>
            <td>Seeta Ram Meena</td>
            <td>Sushil Parihar</td>
            <td>Umesh Kushwaha</td>
            <td>Pramod Kumar Sharma</td>
          </tr>
          <tr>
            <td></td>
            <td>9001196062</td>
            <td>9460137048</td>
            <td>9694968669</td>
            <td>9314088344</td>
            <td>8769496329</td>
            <td>9116680940</td>
            <td>9739202194</td>
            <td>9583658226</td>
            <td>7413875484</td>
            <td>9414526279</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Rana Pratap Nagar</td>
            <td>Arvind Choudhary</td>
            <td>Munesh Kumar Meena</td>
            <td>Dharmesh Chaturvedi</td>
            <td>Monika Nagar</td>
            <td>Kantilal Joshiyal</td>
            <td>Deepak Samriya</td>
            <td>Rahul Singh</td>
            <td>Surendar Soni</td>
            <td>Sumesh Singh</td>
            <td>Ram Roop Meena RD</td>
          </tr>
          <tr>
            <td></td>
            <td>9001031836</td>
            <td>9001196559</td>
            <td>9428549783</td>
            <td></td>
            <td>9982586441</td>
            <td>9602393967</td>
            <td>8963801530</td>
            <td>9414301817</td>
            <td>7340010999</td>
            <td>9785060592</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Udaipur-ZRTI</td>
            <td>Rajesh Vishnoi</td>
            <td>Nishith Shrivastav</td>
            <td>Deepak Paliwal</td>
            <td>Lalit Bharti</td>
            <td>Ram Charan Meena</td>
            <td>Ankit Nirwan</td>
            <td>Hemant Upadhyay</td>
            <td>Prakash Jat</td>
            <td>Rajendra Singh Meena</td>
            <td>Sanjiv Malik</td>
          </tr>
          <tr>
            <td></td>
            <td>9782911118</td>
            <td>7891122206</td>
            <td>7715903261</td>
            <td>9887520309</td>
            <td>9001196308</td>
            <td>8949490973</td>
            <td>9414290213</td>
            <td>6350614740</td>
            <td>6350007092</td>
            <td>9887480129</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AjmerDivision;

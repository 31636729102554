import React from "react";
import { Link } from "react-router-dom";
import styles from "./Header.module.css";
import { FaAngleDown } from "react-icons/fa";

const Navbar = () => {
  return (
    <div className={styles.navbarContainer}>
      <ul className={styles.menu}>
        <li className={styles.menuItem}>
          <Link to="/" className={styles.menuLink}>
            Home
          </Link>
        </li>
        <li className={styles.menuItem}>
          <Link to="/Organisation" className={styles.menuLink}>
            Organisation
          </Link>
        </li>
        <li className={styles.menuItem}>
          <div className={styles.menuItem}>
            <Link to="#" className={styles.menuLink}>
              References <FaAngleDown className={styles.dropicon} />
            </Link>
            <ul className={styles.menuDropdown}>
              <li className={styles.submenuItem}>
                <Link to="/RefNWREU" className={styles.submenuLink}>
                  NWREU
                </Link>
              </li>
              {/* <li className={styles.submenuItem}>
                <Link to="/RefAIRF" className={styles.submenuLink}>
                  AIRF
                </Link>
              </li> */}
            </ul>
          </div>
        </li>
        {/* <li className={styles.menuItem}>
          <div className={styles.menuItem}>
            <Link to="#" className={styles.menuLink}>
              Negotiation Forum <FaAngleDown className={styles.dropicon} />
            </Link>
            <ul className={styles.menuDropdown}>
              <li className={styles.submenuItem}>
                <Link to="/PNM-NWREU" className={styles.submenuLink}>
                  GM-PNM-NWREU
                </Link>
              </li>
              <li className={styles.submenuItem}>
                <Link to="/JCM" className={styles.submenuLink}>
                  JCM
                </Link>
              </li>
            </ul>
          </div>
        </li> */}
        <li className={styles.menuItem}>
          <Link to="/PNM-NWREU" className={styles.menuLink}>
            GM-PNM
          </Link>
        </li>
        <li className={styles.menuItem}>
          <div className={styles.menuItem}>
            <Link to="#" className={styles.menuLink}>
              For Employees <FaAngleDown className={styles.dropicon} />
            </Link>
            <ul className={styles.menuDropdown}>
              <li className={styles.submenuItem}>
                <Link to="/ImpFormats" className={styles.submenuLink}>
                  Important Format
                </Link>
              </li>
              <li className={styles.submenuItem}>
                <Link to="/ImpLinks" className={styles.submenuLink}>
                  Important Links
                </Link>
              </li>
              <li className={styles.submenuItem}>
                <Link to="/Notifications" className={styles.submenuLink}>
                  Notifications
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li className={styles.menuItem}>
          <Link to="/UnionTimes" className={styles.menuLink}>
            Union Times
          </Link>
        </li>
        <li className={styles.menuItem}>
          <Link to="/About" className={styles.menuLink}>
            About & Contact Us
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;

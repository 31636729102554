import React from "react";
import "./Loader.css"; // Import CSS file

const TrainLoader = () => {
  return (
    <div className="loader-container">
      <div className="container">
        <div className="track"></div>
        <div className="train"></div>
      </div>
    </div>
  );
};

export default TrainLoader;

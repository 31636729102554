import React from "react";
import styles from "./Organisation.module.css";

const JodhpurDivison = () => {
  return (
    <div className={styles.TableContainer}>
      <table className={styles.membersTable}>
        <thead>
          <tr>
            <th>Unit</th>
            <th>Chairman</th>
            <th>Secretary</th>
            <th>Vice Chairman</th>
            <th>Vice Chairman</th>
            <th>Vice Chairman</th>
            <th>Asstt. Secretary</th>
            <th>Asstt. Secretary</th>
            <th>Asstt. Secretary</th>
            <th>Asstt. Secretary</th>
            <th>Treasurer</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Jodhpur Division</td>
            <td>Mahendra Vyas</td>
            <td>Manoj Kumar Parihar</td>
            <td>Jasbeer Singh</td>
            <td>Jitendra Dhaka</td>
            <td>Madan Lal Bairwa</td>
            <td>Asha Khinchi</td>
            <td>Banne Singh Panwar</td>
            <td>Rajesh Sharma</td>
            <td>Ravindra Kumar Prajapat</td>
            <td>Sunil Tak</td>
          </tr>
          <tr>
            <td></td>
            <td>9001894722</td>
            <td>9001894721</td>
            <td>9001032491</td>
            <td>9001198447</td>
            <td>9001027538</td>
            <td></td>
            <td>9414477526</td>
            <td>9414243391</td>
            <td>9001198830</td>
            <td>9001198630</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Jodhpur DRM Off.</td>
            <td>Saleem Abdul</td>
            <td>Labh Singh Chouhan</td>
            <td>Arun Kumar Bissa</td>
            <td>Aruna Solanki</td>
            <td>Narpat Ram</td>
            <td>Babulal Choudhry</td>
            <td>Gajendra Singh Rathor</td>
            <td>Ram Kishore Meena</td>
            <td>Raj Mishra</td>
            <td>Bhanwar Singh Daiya</td>
          </tr>
          <tr>
            <td></td>
            <td>9079043624</td>
            <td>9829927271</td>
            <td>9414874090</td>
            <td></td>
            <td>9413100118</td>
            <td>9460685124</td>
            <td>9414293994</td>
            <td>9829906827</td>
            <td>9256117001</td>
            <td>9828555100</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Jodhpur Line</td>
            <td>Neeraj Prasad</td>
            <td>Mahendra Singh Sabarwal</td>
            <td>Lalit Kumar</td>
            <td>Manaram</td>
            <td>Tan Singh</td>
            <td>Asu ram</td>
            <td>Bhanwararam Jani</td>
            <td>Manjoor Ali</td>
            <td>Meetha Lal Meena</td>
            <td>Ram Singh Meena</td>
          </tr>
          <tr>
            <td></td>
            <td>9001198915</td>
            <td>9001198277</td>
            <td>8619320065</td>
            <td>9783835910</td>
            <td>9829882385</td>
            <td>8875150289</td>
            <td>8290113131</td>
            <td>7014740852</td>
            <td>9414815658</td>
            <td>7470973130</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Jodhpur Loco RNG</td>
            <td>Deepak Saxena</td>
            <td>Anup Kumar Trivedi</td>
            <td>Aasu Ram Choudhary</td>
            <td>Ramkishan Bunkar</td>
            <td>Simardeep Bagga</td>
            <td>Bilal Khan</td>
            <td>Pradeep Yadav</td>
            <td>Ramotar Meena</td>
            <td>Sandeep Gurjar</td>
            <td>Vijendra Parajapat</td>
          </tr>
          <tr>
            <td></td>
            <td>9460051952</td>
            <td>9001198436</td>
            <td>9001198447</td>
            <td>9001198440</td>
            <td>9414933336</td>
            <td>9001032422</td>
            <td>9001198446</td>
            <td>9001033208</td>
            <td>9001033194</td>
            <td>9529993143</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Jodhpur Main</td>
            <td>Parmanand Gurjar</td>
            <td>Ashok Singh Meratiya</td>
            <td>Ashish Khanna</td>
            <td>Shankar Lal Chahar</td>
            <td>Upendra Kumar</td>
            <td>Ashok Sharma</td>
            <td>Ramesh Kumar Saulanki</td>
            <td>Vijay Lal Sharma</td>
            <td>Vikram Singh Bhati</td>
            <td>Vijya Vyas</td>
          </tr>
          <tr>
            <td></td>
            <td>9982607978</td>
            <td>9828082022</td>
            <td>9001198834</td>
            <td>9950996667</td>
            <td>9001198244</td>
            <td>9829024448</td>
            <td>7732898772</td>
            <td>9983341891</td>
            <td>9057239515</td>
            <td></td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Bhagat Ki Kothi</td>
            <td>Vijay Singh Dhabhai</td>
            <td>Sharad Joshi</td>
            <td>Ajay Nirala</td>
            <td>Bhawani Singh Rathore</td>
            <td>Rajpal</td>
            <td>Gourav Purohit</td>
            <td>Prafullchand Tiwari</td>
            <td>Ramdevram</td>
            <td>Vikram Singh Mangliya</td>
            <td>Ravindra Singh</td>
          </tr>
          <tr>
            <td></td>
            <td>8949984895</td>
            <td>9828162956</td>
            <td>9001198238</td>
            <td>9001198475</td>
            <td>9116682687</td>
            <td>8114430466</td>
            <td>9001198213</td>
            <td>9024905732</td>
            <td>9829991750</td>
            <td>9829126208</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Barmer</td>
            <td>Moola Ram Chaudhary</td>
            <td>Gajendra Singh</td>
            <td>Durjan Singh Bhati</td>
            <td>Jay Ram Vishoni</td>
            <td>Ganpat Singh</td>
            <td>Bhanaram</td>
            <td>Jagdish Choudhary</td>
            <td>Bhoma Ram</td>
            <td>Kumbha Ram</td>
            <td>Joga Ram</td>
          </tr>
          <tr>
            <td></td>
            <td>9461526702</td>
            <td>9001198019</td>
            <td>9001198429</td>
            <td>9983871400</td>
            <td>9667173240</td>
            <td>8107263891</td>
            <td>8104072396</td>
            <td>9116841109</td>
            <td>9414204362</td>
            <td>9571511665</td>
          </tr>

          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Degana</td>
            <td>Mahendra Raj Gurjar</td>
            <td>Banshi Lal Bhati</td>
            <td>Battilal Meena</td>
            <td>Nema Ram</td>
            <td>Kailash Prajapat</td>
            <td>Ashok Kumar Jhakad</td>
            <td>Dhan Singh Naruka</td>
            <td>Pooran Mal Meena</td>
            <td>Vinod Kumar Sunda</td>
            <td>Siyaram Meena</td>
          </tr>
          <tr>
            <td></td>
            <td>9001097390</td>
            <td>9001198939</td>
            <td>9001198566</td>
            <td>9667299504</td>
            <td>9314298393</td>
            <td>9636333380</td>
            <td>8432302252</td>
            <td>9829726363</td>
            <td>9782401297</td>
            <td>9461042241</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Merta Road</td>
            <td>Avtar Singh Sandhu</td>
            <td>Rajesh Kumar Gurjar</td>
            <td>Abhimanyu Purohit</td>
            <td>Laxmi Narayan Tak</td>
            <td>Sanjay s/o Omprakash</td>
            <td>Madho Das</td>
            <td>Arjun Ram Mahiya</td>
            <td>Ram Swaroop Meena</td>
            <td>Shiv Narayan Mali</td>
            <td>Amar Singh Sankhla</td>
          </tr>
          <tr>
            <td></td>
            <td>9001194828</td>
            <td>9602266317</td>
            <td>9001198217</td>
            <td>9461373277</td>
            <td>8890740195</td>
            <td>9929017693</td>
            <td>9928156654</td>
            <td>9001198214</td>
            <td>8209009119</td>
            <td>9252818867</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Samdari</td>
            <td>Mohan Lal Bishnoi</td>
            <td>Manoj Kumar Sharma</td>
            <td>Kaan Das Sant</td>
            <td>Ashok Khilera</td>
            <td>Nagaram Dewasi</td>
            <td>Akbar Khan</td>
            <td>Bhamlesh Kumar Meena</td>
            <td>Chandra Shekhar Pratipathi</td>
            <td>Mahesh Upadhyay</td>
            <td>Mahendra Singh</td>
          </tr>
          <tr>
            <td></td>
            <td>9001198977</td>
            <td>9001198936</td>
            <td>9950376305</td>
            <td>9001198557</td>
            <td>9680464107</td>
            <td>9001847186</td>
            <td>9950136013</td>
            <td>9829717453</td>
            <td>9001198554</td>
            <td>9414530858</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default JodhpurDivison;

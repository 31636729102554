import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./Nwreu.module.css";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";

const AgendaItems = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://nwreu-backend.vercel.app/pnm-agenda"
        );
        const sortedData = response.data.sort((a, b) => {
          const aParts = a.sNo.split("/").map(Number);
          const bParts = b.sNo.split("/").map(Number);
          return bParts[1] - aParts[1] || bParts[0] - aParts[0];
        });
        setData(sortedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Hide loader when data is fetched
      }
    };

    fetchData();
  }, []);

  return (
    <div className={styles.TableContainer}>
      <Link to="/PNM-NWREU">
        <span className={styles.header}>
          <FaArrowLeft />
          <h2>GM-PNM-NWREU (Click Here)</h2>
        </span>
      </Link>

      {/* Show loader while fetching data */}
      {loading ? (
        <Loader />
      ) : (
        <table className={styles.membersTable}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>PNM Items</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.sNo}</td>
                <td>
                  <a
                    href={item.pnmItemLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.noDecoration} ${styles.linkText}`}
                  >
                    {item.pnmItem}
                  </a>
                </td>
                <td>{item.remarks}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AgendaItems;

import React from "react";
import styles from "./Organisation.module.css";

const JaipurDivision = () => {
  return (
    <div className={styles.TableContainer}>
      <table className={styles.membersTable}>
        <thead>
          <tr>
            <th>Unit</th>
            <th>Chairman</th>
            <th>Secretary</th>
            <th>Vice Chairman</th>
            <th>Vice Chairman</th>
            <th>Vice Chairman</th>
            <th>Asstt. Secretary</th>
            <th>Asstt. Secretary</th>
            <th>Asstt. Secretary</th>
            <th>Asstt. Secretary</th>
            <th>Treasurer</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Jaipur Division</td>
            <td>K S Ahlawat</td>
            <td>Mukesh Chaturvedi</td>
            <td>Vac (Pratiksha Mathur)</td>
            <td>Rajendra Kumar</td>
            <td>Ramniwas Choudhary</td>
            <td>Anoop Kumar Sharma</td>
            <td>Kishan Lal</td>
            <td>Maan Singh Gurjar</td>
            <td>Rajesh Verma</td>
            <td>Rakesh Kumar Yadav</td>
          </tr>
          <tr>
            <td></td>
            <td>9001199849</td>
            <td>9001199724</td>
            <td>9001199645</td>
            <td>9829574200</td>
            <td>9460984893</td>
            <td>9001030209</td>
            <td>8104744374</td>
            <td>9414283544</td>
            <td>9001190107</td>
            <td>9996848964</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>JP-I (Engg)</td>
            <td>Arjun Lal Gurjar</td>
            <td>Lokesh Kumar Meena</td>
            <td>Jitendra Kumar</td>
            <td>Prahlad Dhakad</td>
            <td>VAC</td>
            <td>Arvind Kumar</td>
            <td>Gajanand Sharma</td>
            <td>Nand Kishore Meena</td>
            <td>Vineet Sharma</td>
            <td>Shailendra Ahluwalia</td>
          </tr>
          <tr>
            <td></td>
            <td>9929341119</td>
            <td>9785011737</td>
            <td>9001199213</td>
            <td>9782151794</td>
            <td></td>
            <td>9001199239</td>
            <td>9799737366</td>
            <td>9998738231</td>
            <td>9001199244</td>
            <td>9829143144</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>JP-2 (Comm)</td>
            <td>R K Gupta</td>
            <td>Ramniwas Choudhary</td>
            <td>Ankur Sharma</td>
            <td>Mahipal Singh</td>
            <td>Pappu Lal Meena</td>
            <td>Padam Singh</td>
            <td>Rajesh Saini</td>
            <td>Rajveer Jakhad</td>
            <td>Shiv Prasad Kundariya</td>
            <td>Devendra Singh</td>
          </tr>
          <tr>
            <td></td>
            <td>9983330869</td>
            <td>9460984893</td>
            <td>9783560660</td>
            <td>9116169828</td>
            <td>9413237124</td>
            <td>9875213424</td>
            <td>9829270008</td>
            <td>9413676665</td>
            <td>9413809237</td>
            <td>9280208660</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>JP-3 (Mech+RNG)</td>
            <td>Heera Lal Swami</td>
            <td>Rajesh Verma</td>
            <td>Ghanshyam</td>
            <td>Prahlad Kumar Meena</td>
            <td>Shankar Lal Samota</td>
            <td>Battulal Meena (Vac)</td>
            <td>Jaipal Yadav</td>
            <td>Rajesh Meena</td>
            <td>Ramlal Saini</td>
            <td>Poora Ram Bose</td>
          </tr>
          <tr>
            <td></td>
            <td>9001033321</td>
            <td>9001190107</td>
            <td>9001033343</td>
            <td>9001199450</td>
            <td>8947002120</td>
            <td></td>
            <td>9001199484</td>
            <td>9001033487</td>
            <td>9001199471</td>
            <td>9001199428</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>JP-4 (Med. S&T)</td>
            <td>Mukut Singh</td>
            <td>Desh Raj Singh</td>
            <td>Krishn Kumar Sethi</td>
            <td>Pramod Kumar Gupta</td>
            <td>Tarun Saini</td>
            <td>Bhagirath Mal</td>
            <td>Kailash Gurjar</td>
            <td>Manoj Yadav</td>
            <td>Subhash Meena</td>
            <td>Sunil Kumar Jain</td>
          </tr>
          <tr>
            <td></td>
            <td>8003494071</td>
            <td>9772445533</td>
            <td>8104122902</td>
            <td>9001199846</td>
            <td>9829071727</td>
            <td>8104367070</td>
            <td>9460061895</td>
            <td>9314634299</td>
            <td>9782664249</td>
            <td>8003096790</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>JP-5 (DRM Off.)</td>
            <td>Subhash Kumar</td>
            <td>Rajesh Chhawal</td>
            <td>Deepak Kumar Katewa</td>
            <td>Rinki Yadav</td>
            <td>Satish Jyani</td>
            <td>Badan Singh Meena</td>
            <td>Ram Singh Meena</td>
            <td>Sandeep Yadav</td>
            <td>Umravmal Yadva</td>
            <td>Surendra Punia</td>
          </tr>
          <tr>
            <td></td>
            <td>9001198522</td>
            <td>9001199627</td>
            <td>8955259195</td>
            <td></td>
            <td>9636004263</td>
            <td>9001199899</td>
            <td>9414811454</td>
            <td>9602748026</td>
            <td>9001199056</td>
            <td>9001199624</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Alwar</td>
            <td>Chandru Ram</td>
            <td>Rajkumar Yadav</td>
            <td>K L Meena</td>
            <td>Manish Meena</td>
            <td>Sohan Lal Gurjar</td>
            <td>Farman Khan</td>
            <td>Ramesh Kumar Jat</td>
            <td>Tejpaal Meena</td>
            <td>Trilok Chand Jatav</td>
            <td>Ashish Sharma</td>
          </tr>
          <tr>
            <td></td>
            <td>7737907414</td>
            <td>9887024228</td>
            <td>8890714021</td>
            <td>8440046804</td>
            <td>8440046836</td>
            <td>9982331370</td>
            <td>8112207112</td>
            <td>9001199173</td>
            <td>9950796682</td>
            <td>9116686219</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Bandikui-1</td>
            <td>Anil Kumar Vijay</td>
            <td>Harish Chandra Gurjar</td>
            <td>Ashok Kumar Meena</td>
            <td>Mahendra Singh Mehra</td>
            <td>Sardar Singh Gurjar</td>
            <td>Lokesh Kumar Sharma</td>
            <td>Raghuveer B</td>
            <td>Pradeep Verma</td>
            <td>Mahaveer Singh</td>
            <td>Johari Lal Meena</td>
          </tr>
          <tr>
            <td></td>
            <td>9001199860</td>
            <td>9001563594</td>
            <td>9001033612</td>
            <td>9116686164</td>
            <td>9001194953</td>
            <td>9875047333</td>
            <td>9001030484</td>
            <td>9001033511</td>
            <td>9256106562</td>
            <td>9001030471</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Bandikui-2</td>
            <td>Dinesh Kumar Bairwa</td>
            <td>Mansingh Gurjar</td>
            <td>Dheeraj Kumar</td>
            <td>Ramkesh Meena</td>
            <td>Harkesh Meena</td>
            <td>Ashok Kumar Sharma</td>
            <td>Manish Kumar Gurjar</td>
            <td>Dhara Singh</td>
            <td>Rajaram Gurjar</td>
            <td>Neela Devi</td>
          </tr>
          <tr>
            <td></td>
            <td>9001030778</td>
            <td>9414283544</td>
            <td>7206462010</td>
            <td>9256110439</td>
            <td>9414577548</td>
            <td>8104457859</td>
            <td>8104642940</td>
            <td>8114402109</td>
            <td>6376206033</td>
            <td></td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Bandikui-3</td>
            <td>Dharm Singh Gurjar</td>
            <td>Sanjay Bhatnagar</td>
            <td>Mukesh Poswal</td>
            <td>Om Prakash</td>
            <td>Suresh Chand</td>
            <td>Banwari Lal Meena</td>
            <td>Dayaram Chechi</td>
            <td>Hariom</td>
            <td>Rajesh Yadav</td>
            <td>Dharmendra Kumar Meena</td>
          </tr>
          <tr>
            <td></td>
            <td>9828270938</td>
            <td>9001199196</td>
            <td></td>
            <td>6376960354</td>
            <td>9001199177</td>
            <td>9602539436</td>
            <td>7742712074</td>
            <td>7891568451</td>
            <td>8239979900</td>
            <td>9001297572</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Delhi (NNL)</td>
            <td>Rajendra Prasad Yadav</td>
            <td>Rajveer Singh</td>
            <td>Gorelal Sah</td>
            <td>Mahendra Kumar Heeralal</td>
            <td>Manoj Rao</td>
            <td>Laxmi Narayan Meena</td>
            <td>Pravindra Kumar</td>
            <td>Shyam Sunder Sharma</td>
            <td>Sushil Kumar</td>
            <td>Karmveer Khan</td>
          </tr>
          <tr>
            <td></td>
            <td>9416416275</td>
            <td>9636688258</td>
            <td>9001199548</td>
            <td>9729595002</td>
            <td>9462555525</td>
            <td>8949234376</td>
            <td>9468057888</td>
            <td>9813170421</td>
            <td>8901502519</td>
            <td>9812148239</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Phulera-1</td>
            <td>Ram Singh Yadav</td>
            <td>Narendra Singh Chahar</td>
            <td>Abhishek Dixit</td>
            <td>Mukesh Kumar Verma</td>
            <td>Manoj Kumar Yadav</td>
            <td>Gulsingh Meena</td>
            <td>Hari Singh Choudhary</td>
            <td>Suresh Chand Bairwa</td>
            <td>Vijay Singh</td>
            <td>Shankar Lal Choudhary</td>
          </tr>
          <tr>
            <td></td>
            <td>7976011443</td>
            <td>9587508011</td>
            <td>8003494073</td>
            <td>9571622252</td>
            <td>9001199381</td>
            <td>9001033528</td>
            <td>9782353732</td>
            <td>9784470389</td>
            <td>9116686184</td>
            <td>9664270073</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Phulera-2</td>
            <td>Prahlad Ram</td>
            <td>Hameer Singh Maharia</td>
            <td>Jabbar Mohammad</td>
            <td>Pabupal Kumawat</td>
            <td>Rakesh Kumar Kumawat</td>
            <td>Rajendra Sheela</td>
            <td>Rajnesh Meena</td>
            <td>Ramchandra</td>
            <td>Roop Singh Yadav</td>
            <td>Babu Lal Meena</td>
          </tr>
          <tr>
            <td></td>
            <td>8209797351</td>
            <td>9001030607</td>
            <td></td>
            <td>8058060192</td>
            <td>9001199147</td>
            <td>9982046210</td>
            <td>9116686676</td>
            <td>9660326622</td>
            <td>979965138</td>
            <td>9001030558</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Phulera-3</td>
            <td>Suresh Jangid</td>
            <td>Rajendra Choudhary</td>
            <td>Bhavy Obroy</td>
            <td>Lahat Sab Meena</td>
            <td>Vijendra Kumar</td>
            <td>Chetan Prakash Kumawat</td>
            <td>Madan Lal Choudhary</td>
            <td>Pawan Kumar Yadav</td>
            <td>Sualal Balai</td>
            <td>Ganesh Narayan</td>
          </tr>
          <tr>
            <td></td>
            <td>6377828912</td>
            <td>7014923505</td>
            <td>9667370204</td>
            <td>901980102</td>
            <td>9610888910</td>
            <td>9983884472</td>
            <td>9783958890</td>
            <td>9602856091</td>
            <td>9982283689</td>
            <td>9928317855</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Kishangarh</td>
            <td>Deewan Mohammad</td>
            <td>Gopal Lal Khoji</td>
            <td>Kajodmal</td>
            <td>Ramavtar Jaat</td>
            <td>Ramkaran Choudhary</td>
            <td>Ashok Kumar Jain</td>
            <td>Lakshman Prasad</td>
            <td>Gulab Mohammad</td>
            <td>Rajendra Kumar Saini</td>
            <td>Gopalram</td>
          </tr>
          <tr>
            <td></td>
            <td>9928021337</td>
            <td>9829118802</td>
            <td>8104420209</td>
            <td>8209060014</td>
            <td>9667828723</td>
            <td>9351229490</td>
            <td>9660566556</td>
            <td>9351229223</td>
            <td>9001212342</td>
            <td>9413092929</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Rewari</td>
            <td>Mukesh Kumar Yadav</td>
            <td>Rajkumar Sharma</td>
            <td>Rakesh Kumar</td>
            <td>Naveen Kumar</td>
            <td>Pankaj Kumar Ghai</td>
            <td>Sarvesh Kumar Gupta</td>
            <td>Nekbadan Sharma</td>
            <td>Ratan Singh Yadav</td>
            <td>Sandeep Kumar</td>
            <td>Gulshan Kumar</td>
          </tr>
          <tr>
            <td></td>
            <td>9467840759</td>
            <td>8003153033</td>
            <td>9991342336</td>
            <td>8683833319</td>
            <td>9416594549</td>
            <td>9351229169</td>
            <td>9001030420</td>
            <td>9416980054</td>
            <td>8708989362</td>
            <td>9887860634</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Ringus</td>
            <td>Amipal Singh</td>
            <td>Arjun Singh Bijarinia</td>
            <td>Narendra Jangid</td>
            <td>Pawan Kumar Sharma</td>
            <td>Raghuveer Dayal</td>
            <td>H P Chopra (Har Phool)</td>
            <td>Rajesh Kumar Kohli</td>
            <td>S R Saini</td>
            <td>Tofanmal Bagdi</td>
            <td>Mukesh Chand Meena</td>
          </tr>
          <tr>
            <td></td>
            <td>9950798429</td>
            <td>9001644554</td>
            <td>9001900620</td>
            <td>9782224983</td>
            <td>9983338614</td>
            <td>8740081190</td>
            <td>8003039159</td>
            <td>9413070615</td>
            <td>8440049388</td>
            <td>9001199763</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Sikar-1</td>
            <td>Suresh Kumar Dhaka</td>
            <td>Balbeer Singh</td>
            <td>Azad Singh</td>
            <td>Sanjeev Kumar</td>
            <td>Ramesh Kumar Gurjar</td>
            <td>P M Kumawat</td>
            <td>Narendra Kumar</td>
            <td>Ganesh Kumar Soni</td>
            <td>N K Gehlot</td>
            <td>Bharatveer Singh</td>
          </tr>
          <tr>
            <td></td>
            <td>9001199858</td>
            <td>9664084397</td>
            <td>9799645579</td>
            <td>941394553</td>
            <td>9116169539</td>
            <td>9412307046</td>
            <td>9950772450</td>
            <td>9782886007</td>
            <td>7014747091</td>
            <td>8269105601</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Sikar-2</td>
            <td>Rajendra Kumar</td>
            <td>Krishnanand Sharma</td>
            <td>Amarpal</td>
            <td>Rahul Kumar</td>
            <td>Vijaypal</td>
            <td>Jitendra Kumar (Ret)</td>
            <td>Rohit Kumar Sharma</td>
            <td>Subhash Kumar Budi</td>
            <td>Vinod Kumar</td>
            <td>Naveen Kumar Mishra</td>
          </tr>
          <tr>
            <td></td>
            <td>9116686618</td>
            <td>9001199774</td>
            <td>6375727309</td>
            <td>9001199268</td>
            <td>9079090097</td>
            <td>9982275968</td>
            <td>9001199134</td>
            <td>9571769232</td>
            <td>9928563253</td>
            <td>9001199298</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
          <tr>
            <td className={styles.unitCell}>Sawai Madhopur (BNLW)</td>
            <td>Bacchu Lal Meena</td>
            <td>Madan Singh Rajawat</td>
            <td>Anil Tripathi</td>
            <td>Jeewan Singh Meena</td>
            <td>Rajendra Choudhary</td>
            <td>Roshan Lal Meena</td>
            <td>Dhirendra Singh</td>
            <td>Deepak Kumar Bharti</td>
            <td>Pappu Lal Yogi</td>
            <td>Roshan Lal Meena</td>
          </tr>
          <tr>
            <td></td>
            <td>9462032666</td>
            <td>9116686260</td>
            <td>9413531097</td>
            <td>9929455773</td>
            <td>9468693021</td>
            <td>9460951093</td>
            <td>9414405405</td>
            <td>9001199245</td>
            <td>9660270372</td>
            <td>9460951093</td>
          </tr>
          <tr>
            <td colSpan="10"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default JaipurDivision;

import React, { useState, useEffect } from "react";
import styles from "./Admin.module.css";
import axios from "axios"; // Import axios for making HTTP requests
import { useNavigate } from "react-router-dom"; // Import useNavigate

const Admin = () => {
  // State variables
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [authenticated, setAuthenticated] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [error, setError] = useState("");
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [formDetails, setFormDetails] = useState({});
  const [lastActivity, setLastActivity] = useState(Date.now());
  const navigate = useNavigate();

  // Hardcoded valid username and password (for demonstration)
  const validUsername = "admin";
  const validPassword = "nwreu@9191";

  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let year = 2010; year <= currentYear; year++) {
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle login form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if username and password match
    if (
      formData.username === validUsername &&
      formData.password === validPassword
    ) {
      setAuthenticated(true);
      setShowLoginForm(false);
      setError("");
      setLastActivity(Date.now());
    } else {
      setAuthenticated(false);
      setError("Username or password incorrect");
    }

    // Clear the form fields after submission
    setFormData({
      username: "",
      password: "",
    });
  };

  // Handle dropdown change
  const handleDropdownChange = (e) => {
    setSelectedOption(e.target.value);
    setFormDetails({});
    setLastActivity(Date.now());
  };

  // Handle form details change
  const handleFormDetailsChange = (e) => {
    const { name, value } = e.target;
    setFormDetails({
      ...formDetails,
      [name]: value,
    });
    setLastActivity(Date.now());
  };

  // Handle form submission for different forms
  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Determine the API endpoint based on selectedOption
    let endpoint = "";
    switch (selectedOption) {
      case "Union Times":
        endpoint = "https://nwreu-backend.vercel.app/ut";
        break;
      case "Important Formats":
        endpoint = "https://nwreu-backend.vercel.app/impformats";
        break;
      case "PNM - NWREU":
        endpoint = "https://nwreu-backend.vercel.app/pnm-nwreu";
        break;
      case "PNM - Agenda":
        endpoint = "https://nwreu-backend.vercel.app/pnm-agenda";
        break;
      case "References - NWREU":
        endpoint = "https://nwreu-backend.vercel.app/ref-nwreu";
        break;
      case "References - AIRF":
        endpoint = "https://nwreu-backend.vercel.app/ref-airf";
        break;
      case "JCM":
        endpoint = "https://nwreu-backend.vercel.app/jcm";
        break;
      case "Notifications":
        endpoint = "https://nwreu-backend.vercel.app/notification";
        break;
      case "Delete Notifications":
        endpoint = "https://www.nwreu.org/RemoveNotifications";
        break;
      default:
        return; // Exit if no valid option
    }

    // Make POST request to the selected endpoint
    axios
      .post(endpoint, formDetails)
      .then((response) => {
        console.log("Form submitted successfully:", response.data);
        alert("Submitted Successfully");
        setFormDetails({});
        setLastActivity(Date.now());
      })
      .catch((error) => {
        alert("Error Occured");
        console.error("Error submitting form:", error);
        setLastActivity(Date.now());
      });
  };

  // Check for inactivity and reauthenticate after 10 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      if (Date.now() - lastActivity >= 10 * 60 * 1000) {
        setAuthenticated(false);
        setShowLoginForm(true);
      }
    }, 60 * 1000); // Check every minute

    return () => clearInterval(interval);
  }, [lastActivity]);

  // Render different forms based on dropdown selection
  const renderForm = () => {
    switch (selectedOption) {
      case "Retired Employees":
        return (
          <form className={styles.form} onSubmit={handleFormSubmit}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Heading:</label>
              <input
                type="text"
                name="heading"
                value={formDetails.heading || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Link:</label>
              <input
                type="text"
                name="link"
                value={formDetails.link || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>
            <div className={styles.buttonContainer}>
              <button type="submit" className={styles.button}>
                Submit
              </button>
            </div>
          </form>
        );

      case "Important Formats":
        return (
          <form className={styles.form} onSubmit={handleFormSubmit}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Heading:</label>
              <input
                type="text"
                name="heading"
                value={formDetails.heading || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Link:</label>
              <input
                type="text"
                name="link"
                value={formDetails.link || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>
            <div className={styles.buttonContainer}>
              <button type="submit" className={styles.button}>
                Submit
              </button>
            </div>
          </form>
        );

      case "Union Times":
        return (
          <form className={styles.form} onSubmit={handleFormSubmit}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Month:</label>
              <select
                name="month"
                value={formDetails.month || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              >
                <option value="">Select a month</option>
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
              </select>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Year:</label>
              <input
                type="number"
                name="year"
                value={formDetails.year || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Link:</label>
              <input
                type="text"
                name="link"
                value={formDetails.link || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>
            <div className={styles.buttonContainer}>
              <button type="submit" className={styles.button}>
                Submit
              </button>
            </div>
          </form>
        );

      case "References - NWREU":
        return (
          <form className={styles.form} onSubmit={handleFormSubmit}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Date:</label>
              <input
                type="date"
                name="date"
                value={formDetails.date || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Subject:</label>
              <input
                type="text"
                name="subject"
                value={formDetails.subject || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Subject Link:</label>
              <input
                type="text"
                name="subjectLink"
                value={formDetails.subjectLink || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Reply Link:</label>
              <input
                type="text"
                name="replyLink"
                value={formDetails.replyLink || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
              />
            </div>
            <div className={styles.buttonContainer}>
              <button type="submit" className={styles.button}>
                Submit
              </button>
            </div>
          </form>
        );

      case "Notifications":
        return (
          <form className={styles.form} onSubmit={handleFormSubmit}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Date:</label>
              <input
                type="date"
                name="date"
                value={formDetails.date || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Subject:</label>
              <input
                type="text"
                name="subject"
                value={formDetails.subject || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Subject Link:</label>
              <input
                type="text"
                name="subjectLink"
                value={formDetails.subjectLink || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
              />
            </div>

            <div className={styles.buttonContainer}>
              <button type="submit" className={styles.button}>
                Submit
              </button>
            </div>
          </form>
        );

      case "Delete Notifications":
        return (
          <form className={styles.form} onSubmit={handleFormSubmit}>
            <div className={styles.buttonContainer}>
              <button
                type="button"
                className={styles.button}
                onClick={() => navigate("/RemoveNotification")}
              >
                {" "}
                Delete Notification
              </button>
            </div>
          </form>
        );

      case "References - AIRF":
        return (
          <form className={styles.form} onSubmit={handleFormSubmit}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Date:</label>
              <input
                type="date"
                name="date"
                value={formDetails.date || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Subject:</label>
              <input
                type="text"
                name="subject"
                value={formDetails.subject || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Subject Link:</label>
              <input
                type="text"
                name="subjectLink"
                value={formDetails.subjectLink || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Reply Link:</label>
              <input
                type="text"
                name="replyLink"
                value={formDetails.replyLink || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
              />
            </div>
            <div className={styles.buttonContainer}>
              <button type="submit" className={styles.button}>
                Submit
              </button>
            </div>
          </form>
        );

      case "JCM":
        return (
          <form className={styles.form} onSubmit={handleFormSubmit}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Date:</label>
              <input
                type="date"
                name="date"
                value={formDetails.date || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Subject:</label>
              <input
                type="text"
                name="subject"
                value={formDetails.subject || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Subject Link:</label>
              <input
                type="text"
                name="subjectLink"
                value={formDetails.subjectLink || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Reply Link:</label>
              <input
                type="text"
                name="replyLink"
                value={formDetails.replyLink || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
              />
            </div>
            <div className={styles.buttonContainer}>
              <button type="submit" className={styles.button}>
                Submit
              </button>
            </div>
          </form>
        );

      case "PNM - NWREU":
        return (
          <form className={styles.form} onSubmit={handleFormSubmit}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Dates:</label>
              <input
                type="text"
                name="date"
                value={formDetails.date || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label className={styles.label}>Minute:</label>
              <input
                type="text"
                name="minutes"
                value={formDetails.minutes || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label className={styles.label}>Minute Link:</label>
              <input
                type="text"
                name="minutesLink"
                value={formDetails.minutesLink || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label className={styles.label}>Outset Reply:</label>
              <input
                type="text"
                name="outsetReply"
                value={formDetails.outsetReply || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
              />
            </div>

            <div className={styles.formGroup}>
              <label className={styles.label}>Outset Reply Link:</label>
              <input
                type="text"
                name="outsetReplyLink"
                value={formDetails.outsetReplyLink || ""}
                onChange={handleFormDetailsChange}
                className={styles.input}
              />
            </div>

            <div className={styles.buttonContainer}>
              <button type="submit" className={styles.button}>
                Submit
              </button>
            </div>
          </form>
        );

      case "PNM - Agenda":
        return (
          <form className={styles.form} onSubmit={handleFormSubmit}>
            <div className={styles.formGroup}>
              <label className={styles.label}>S.no:</label>
              <input
                type="text"
                name="sNo"
                value={formDetails.sNo}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>

            {/* <div className={styles.formGroup}>
              <label className={styles.label}>Year:</label>
              <select
                name="year"
                value={formDetails.year}
                onChange={handleFormDetailsChange}
                className={styles.input}
        
              >
                <option value="">Select Year</option>
                {yearOptions}
              </select>
            </div> */}

            <div className={styles.formGroup}>
              <label className={styles.label}>PNM Item:</label>
              <input
                type="text"
                name="pnmItem"
                value={formDetails.pnmItem}
                onChange={handleFormDetailsChange}
                className={styles.input}
                required
              />
            </div>

            {/* <div className={styles.formGroup}>
              <label className={styles.label}>PNM Item Link:</label>
              <input
                type="text"
                name="pnmItemLink"
                value={formDetails.pnmItemLink}
                onChange={handleFormDetailsChange}
                className={styles.input}
              />
            </div> */}

            <div className={styles.formGroup}>
              <label className={styles.label}>Remarks:</label>
              <input
                type="text"
                name="remarks"
                value={formDetails.remarks}
                onChange={handleFormDetailsChange}
                className={styles.input}
              />
            </div>

            <div className={styles.buttonContainer}>
              <button type="submit" className={styles.button}>
                Submit
              </button>
            </div>
          </form>
        );

      default:
        return null;
    }
  };

  return (
    <div className={styles.adminContainer}>
      <h2>Admin Panel</h2>

      <div className={styles.container}>
        {showLoginForm ? (
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Username:</label>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                placeholder="Enter the username"
                className={styles.input}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Password:</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Enter the password"
                className={styles.input}
                required
              />
            </div>
            <div className={styles.buttonContainer}>
              <button type="submit" className={styles.button}>
                Submit
              </button>
            </div>
            {error && <p className={styles.error}>{error}</p>}
          </form>
        ) : (
          <>
            <div className={styles.formGroup}>
              <label className={styles.label}>Select Option:</label>
              <select
                name="option"
                value={selectedOption}
                onChange={handleDropdownChange}
                className={styles.input}
                required
              >
                <option value="">Select an option</option>
                <option value="Retired Employees">Retired Employees</option>
                <option value="Important Formats">Important Formats</option>
                <option value="References - NWREU">References - NWREU</option>
                <option value="References - AIRF">References - AIRF</option>
                <option value="PNM - NWREU">PNM - NWREU</option>
                <option value="PNM - Agenda">PNM - Agenda</option>
                <option value="JCM">JCM</option>
                <option value="Union Times">Union Times</option>
                <option value="Notifications">Notifications</option>
                <option value="Delete Notifications">
                  Delete Notification
                </option>
              </select>
            </div>

            {selectedOption && (
              <div className={styles.formGroup}>{renderForm()}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Admin;

import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./Nwreu.module.css";

const RemoveNotification = () => {
  const [data, setData] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://nwreu-backend.vercel.app/notification"
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Sort data by date in descending order (newest date on top)
  const sortedData = [...data].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  // Handle radio button change
  const handleSelectionChange = (id) => {
    setSelectedNotification(id);
  };

  // Handle deletion
  const handleDelete = async () => {
    if (!selectedNotification) {
      alert("Please select a notification to delete.");
      return;
    }

    try {
      await axios.delete(
        `https://nwreu-backend.vercel.app/notification/${selectedNotification}`
      );
      alert("Notification deleted successfully!");

      // Remove the deleted notification from the list
      setData(data.filter((item) => item._id !== selectedNotification));
      setSelectedNotification(null);
    } catch (error) {
      console.error("Error deleting notification:", error);
      alert("Failed to delete notification.");
    }
  };

  return (
    <div className={styles.TableContainer}>
      <table className={styles.membersTable}>
        <thead>
          <tr>
            <th>Select</th>
            <th>Date</th>
            <th>Subject</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item) => (
            <tr key={item._id}>
              <td style={{ textAlign: "center" }}>
                <input
                  type="radio"
                  name="notification"
                  value={item._id}
                  onChange={() => handleSelectionChange(item._id)}
                  style={{ transform: "scale(1.3)", cursor: "pointer" }}
                />
              </td>
              <td>{new Date(item.date).toLocaleDateString()}</td>
              <td>
                <a
                  href={item.subjectLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  {item.subject}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Delete Button */}
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <button
          onClick={handleDelete}
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            backgroundColor: "#e74c3c",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Delete Selected Notification
        </button>
      </div>
    </div>
  );
};

export default RemoveNotification;

import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./Nwreu.module.css";
import Loader from "../Loader/Loader"; // Import Loader component

const Notification = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://nwreu-backend.vercel.app/notification"
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Hide loader when data is fetched
      }
    };

    fetchData();
  }, []);

  // Sort data by date in descending order (newest date first)
  const sortedData = [...data].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  return (
    <div className={styles.TableContainer}>
      {/* Show loader while fetching data */}
      {loading ? (
        <Loader />
      ) : sortedData.length === 0 ? (
        <p className={styles.noData}>No new notifications available.</p>
      ) : (
        <table className={styles.membersTable}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Subject</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item, index) => (
              <tr key={index}>
                <td>{new Date(item.date).toLocaleDateString()}</td>
                <td>
                  <a
                    href={item.subjectLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                  >
                    {item.subject}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Notification;

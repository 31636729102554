// Homepage.js
import React from "react";
import styles from "./Organisation.module.css";
import person from "../../assets/person.png";
import { Link } from "react-router-dom";
import person1 from "../../assets/OrgPhoto/1-_NWREU_Manoj_Parihar-removebg-preview.png";
import person2 from "../../assets/OrgPhoto/2-_NWREU_Mukesh_Chaturvedi-removebg-preview.png";
import person3 from "../../assets/OrgPhoto/3-_NWREU_Mukesh_Mathur-removebg-preview.png";
import person4 from "../../assets/OrgPhoto/4-_NWREU_Vipul_Saxena_Tresurar-removebg-preview.png";
import person5 from "../../assets/OrgPhoto/5-_NWREU_Arun_Gupta-removebg-preview.png";
import person6 from "../../assets/OrgPhoto/6-_NWREU_Brijesh_ojha-removebg-preview.png";
import person7 from "../../assets/OrgPhoto/7-_NWREU_N_Chahar-removebg-preview.png";
import person8 from "../../assets/OrgPhoto/8-_NWREU_Vijay_Shrimali-removebg-preview.png";
import person10 from "../../assets/OrgPhoto/10-_Gopal_Meena_WNWREU-removebg-preview.png";
import person11 from "../../assets/OrgPhoto/11-_NWREU_Jagdish_Singh-2-removebg-preview.png";
import person12 from "../../assets/OrgPhoto/12_NWREU_Meena_Saxena-removebg-preview.png";
import person13 from "../../assets/OrgPhoto/13-_NWREU_Mohan_Das_Chelani-removebg-preview.png";
import person14 from "../../assets/OrgPhoto/14_NWREU_Narendra_Bhardwaj2-removebg-preview.png";
import person15 from "../../assets/OrgPhoto/15_NWREU_S_Moin_hussain-removebg-preview.png";
import person16 from "../../assets/OrgPhoto/16_Sanjay_Meena-removebg-preview.png";
import person17 from "../../assets/OrgPhoto/17-_NWREU_Pramod_Yadav-removebg-preview.png";
import person18 from "../../assets/OrgPhoto/18_NWREU_Rajeev_Sharma-removebg-preview.png";
import person19 from "../../assets/OrgPhoto/19_NWREU_shashi_prakash-removebg-preview.png";
import person20 from "../../assets/OrgPhoto/20_NWREU_K_S_Ahlawat_PP__2_-removebg-preview.png";
import person21 from "../../assets/OrgPhoto/21_NWREU_Mahendra_Vyas-removebg-preview.png";
import person22 from "../../assets/OrgPhoto/22_NWREU_Rajeev_Saran-removebg-preview.png";
import person23 from "../../assets/OrgPhoto/23_NWREU_Gajanand_Mawar-2-removebg-preview.png";
import person24 from "../../assets/OrgPhoto/24-_NWREU_Kamlesh_Sharma-removebg-preview.png";
import person25 from "../../assets/OrgPhoto/25_NWREU_Dinesh_Singh-removebg-preview.png";
import person26 from "../../assets/OrgPhoto/26_NWREU_Madan_Lal_Gurjar-removebg-preview.png";
import person27 from "../../assets/OrgPhoto/27_NWREU_Chetraam_meena-removebg-preview.png";
import person28 from "../../assets/OrgPhoto/28_NWREU_Jitendra_Gurjar-removebg-preview.png";
import person29 from "../../assets/OrgPhoto/29_NWREU_Krishna_koushik-2-removebg-preview.png";
import person30 from "../../assets/OrgPhoto/30_NWREU_Mukesh_Kumar_Meena-removebg-preview.png";
import person31 from "../../assets/OrgPhoto/31_NWREU_Rajendra_Singh-removebg-preview.png";
import person32 from "../../assets/OrgPhoto/32_NWREU_Samder_Singh-removebg-preview.png";
// import person33 from "../../assets/OrgPhoto/33_NWREU_Subhash_Pareek-removebg-preview.png";
import person34 from "../../assets/OrgPhoto/34_NWREU_Laxmi_Dewal-W_C_Member-removebg-preview.png";
// import person35 from "../../assets/OrgPhoto/35_NWREU_Woman_Chairperson_Ms._Sarika_Jain-removebg-preview.png";
import person36 from "../../assets/OrgPhoto/36_NWREU_Shweta_Haris-removebg-preview.png";
import person37 from "../../assets/OrgPhoto/37_NWREU_Vijya_Vyas-removebg-preview.png";
import person38 from "../../assets/OrgPhoto/38_NWREU_Vineet_Maan-removebg-preview.png";
import person39 from "../../assets/OrgPhoto/Pawan Juneja.png";
import person40 from "../../assets/OrgPhoto/Sandeep Janu.png";
import person41 from "../../assets/OrgPhoto/khirajram bhati.png";
import person42 from "../../assets/OrgPhoto/Sunil_Sharma_JC_Bank.png";
import person43 from "../../assets/OrgPhoto/43_NWREU_Treasurer-_Rakesh_Yadav-removebg-preview.png";
import person44 from "../../assets/OrgPhoto/44_NWREU_Rajesh_verma-removebg-preview.png";
import person45 from "../../assets/OrgPhoto/45 NWREU Vibhor_Mishra- JCCS Aiiremovebg-preview.png";
import person46 from "../../assets/OrgPhoto/Bhawna Verma.png";
import person47 from "../../assets/OrgPhoto/Sohan Lal Kasana.png";
import person48 from "../../assets/OrgPhoto/48_NWREU_Deshraj_Singh-removebg-preview.png";

function Organisation() {
  return (
    <div className={styles.C}>
      {/* Container1 */}
      <div className={styles.container}>
        {/* Existing content */}
        <div className={styles.row}>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person1} alt="Person 1" className={styles.image} />
            <div className={styles.name}>मनोज कुमार परिहार</div>
            <div className={styles.post}>ज़ोनल अध्यक्ष</div>
            <div className={styles.phone}>
              <a href="tel:+919001894721" className={styles.phoneLink}>
                9001894721
              </a>
            </div>
          </div>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person2} alt="Person 1" className={styles.image} />
            <div className={styles.name}>मुकेश चतुर्वेदी</div>
            <div className={styles.post}>ज़ोनल कार्यकारी अध्यक्ष</div>
            <div className={styles.phone}>
              <a href="tel:+919001199724" className={styles.phoneLink}>
                9001199724
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person3} alt="Person 1" className={styles.image} />
            <div className={styles.name}>मुकेश माथुर</div>
            <div className={styles.post}>महामंत्री</div>
            <div className={styles.phone}>
              <a href="tel:+919001195692" className={styles.phoneLink}>
                9001195692
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person4} alt="Person 1" className={styles.image} />
            <div className={styles.name}>विपुल सक्सेना</div>
            <div className={styles.post}>ज़ोनल कोषाध्यक्ष</div>
            <div className={styles.phone}>
              <a href="tel:+919001194539" className={styles.phoneLink}>
                9001194539
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person5} alt="Person 1" className={styles.image} />
            <div className={styles.name}>अरुण गुप्ता</div>
            <div className={styles.post}>ज़ोनल सचिव-AIRF</div>
            <div className={styles.phone}>
              <a href="tel:+919829242480" className={styles.phoneLink}>
                9829242480
              </a>
            </div>{" "}
          </div>
        </div>
      </div>
      {/* Container1 */}

      {/* Container2 */}
      <div className={styles.container}>
        {/* Existing content */}
        <div className={styles.row}>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person6} alt="Person 1" className={styles.image} />
            <div className={styles.name}>ब्रजेश कुमार ओझा</div>
            <div className={styles.post}>उपाध्यक्ष</div>
            <div className={styles.phone}>
              <a href="tel:+919413939828" className={styles.phoneLink}>
                9413939828
              </a>
            </div>
          </div>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person7} alt="Person 1" className={styles.image} />
            <div className={styles.name}>नरेंद्र सिंह चाहर</div>
            <div className={styles.post}>उपाध्यक्ष</div>
            <div className={styles.phone}>
              <a href="tel:+9190010333417" className={styles.phoneLink}>
                90010333417
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person8} alt="Person 1" className={styles.image} />
            <div className={styles.name}>विजय श्रीमाली</div>
            <div className={styles.post}>उपाध्यक्ष</div>
            <div className={styles.phone}>
              <a href="tel:+919001197655" className={styles.phoneLink}>
                9001197655
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person} alt="Person 1" className={styles.image} />
            <div className={styles.name}>रिक्त</div>
            <div className={styles.post}>उपाध्यक्ष</div>
            <div className={styles.phone}>
              <a href="#" className={styles.phoneLink}></a>
            </div>{" "}
          </div>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person10} alt="Person 1" className={styles.image} />
            <div className={styles.name}>गोपाल मीना</div>
            <div className={styles.post}>सहायक महामंत्री</div>
            <div className={styles.phone}>
              <a href="tel:+919001199328" className={styles.phoneLink}>
                9001199328
              </a>
            </div>{" "}
          </div>{" "}
        </div>
      </div>
      {/* Container2 */}

      {/* Container3 */}
      <div className={styles.container}>
        {/* Existing content */}
        <div className={styles.row}>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person11} alt="Person 1" className={styles.image} />
            <div className={styles.name}>जगदीश सिंह</div>
            <div className={styles.post}>सहायक महामंत्री</div>
            <div className={styles.phone}>
              <a href="tel:+919001194637" className={styles.phoneLink}>
                9001194637
              </a>
            </div>
          </div>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person12} alt="Person 1" className={styles.image} />
            <div className={styles.name}>मीना सक्सेना</div>
            <div className={styles.post}>सहायक महामंत्री</div>
            <div className={styles.phone}></div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person13} alt="Person 1" className={styles.image} />
            <div className={styles.name}>मोहनदास चेलानी</div>
            <div className={styles.post}>सहायक महामंत्री</div>
            <div className={styles.phone}>
              <a href="tel:+919001196617" className={styles.phoneLink}>
                9001196617
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person14} alt="Person 1" className={styles.image} />
            <div className={styles.name}>नरेंद्र भारद्वाज</div>
            <div className={styles.post}>सहायक महामंत्री</div>
            <div className={styles.phone}>
              <a href="tel:+917087002666" className={styles.phoneLink}>
                7087002666
              </a>
            </div>{" "}
          </div>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person15} alt="Person 1" className={styles.image} />
            <div className={styles.name}>सैयद मोइन हुसैन</div>
            <div className={styles.post}>सहायक महामंत्री</div>
            <div className={styles.phone}>
              <a href="tel:+91701486909" className={styles.phoneLink}>
                701486909
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person16} alt="Person 1" className={styles.image} />
            <div className={styles.name}>संजय मीना</div>
            <div className={styles.post}>सहायक महामंत्री</div>
            <div className={styles.phone}>
              <a href="tel:+919001198478" className={styles.phoneLink}>
                9001198478
              </a>
            </div>{" "}
          </div>{" "}
        </div>
      </div>
      {/* Container3 */}

      {/* Container4 */}
      <div className={styles.container}>
        {/* Existing content */}
        <div className={styles.row}>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person17} alt="Person 1" className={styles.image} />
            <div className={styles.name}>प्रमोद कुमार</div>
            <div className={styles.post}>WCM & मण्डल मंत्री बीकानेर</div>
            <div className={styles.phone}>
              <a href="tel:+919001197622" className={styles.phoneLink}>
                9001197622
              </a>
            </div>
          </div>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person18} alt="Person 1" className={styles.image} />
            <div className={styles.name}>राजीव शर्मा</div>
            <div className={styles.post}>WCM & मण्डल अध्यक्ष अजमेर</div>
            <div className={styles.phone}>
              <a href="tel:+919828180069" className={styles.phoneLink}>
                9828180069
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person19} alt="Person 1" className={styles.image} />
            <div className={styles.name}>शशि प्रकाश</div>
            <div className={styles.post}>WCM & मण्डल अध्यक्ष बीकानेर</div>
            <div className={styles.phone}>
              <a href="tel:+919466135435" className={styles.phoneLink}>
                9466135435
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person20} alt="Person 1" className={styles.image} />
            <div className={styles.name}>के एस अहलावत</div>
            <div className={styles.post}>WCM & मण्डल अध्यक्ष जयपुर</div>
            <div className={styles.phone}>
              <a href="tel:+919001199723" className={styles.phoneLink}>
                9001199723
              </a>
            </div>{" "}
          </div>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person21} alt="Person 1" className={styles.image} />
            <div className={styles.name}>महेंद्र व्यास</div>
            <div className={styles.post}>WCM & मण्डल अध्यक्ष जोधपुर</div>
            <div className={styles.phone}>
              <a href="tel:+919001198241" className={styles.phoneLink}>
                9001198241
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person22} alt="Person 1" className={styles.image} />
            <div className={styles.name}>राजीव कुमार सारण</div>
            <div className={styles.post}>WCM & सचिव प्र.का. शाखा जयपुर</div>
            <div className={styles.phone}>
              <a href="tel:+919001195831" className={styles.phoneLink}>
                9001195831
              </a>
            </div>{" "}
          </div>{" "}
        </div>
      </div>
      {/* Container4 */}

      {/* Container5 */}
      <div className={styles.container}>
        {/* Existing content */}
        <div className={styles.row}>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person23} alt="Person 1" className={styles.image} />
            <div className={styles.name}>गजानन्द मावर</div>
            <div className={styles.post}>WCM & सचिव अजमेर कैरिज कारख़ाना</div>
            <div className={styles.phone}>
              <a href="tel:+919001296456" className={styles.phoneLink}>
                9001296456
              </a>
            </div>
          </div>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person24} alt="Person 1" className={styles.image} />
            <div className={styles.name}>कमलेश शर्मा</div>
            <div className={styles.post}>WCM & सचिव अजमेर विद्युत कारख़ाना</div>
            <div className={styles.phone}>
              <a href="tel:+918003715744" className={styles.phoneLink}>
                8003715744
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person25} alt="Person 1" className={styles.image} />
            <div className={styles.name}>दिनेश सिंह</div>
            <div className={styles.post}>WCM & सचिव बीकानेर कार्यशाला</div>
            <div className={styles.phone}>
              <a href="tel:+919983320018" className={styles.phoneLink}>
                9983320018
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person26} alt="Person 1" className={styles.image} />
            <div className={styles.name}>मदन लाल गुर्जर</div>
            <div className={styles.post}>WCM & सचिव जोधपुर कार्यशाला</div>
            <div className={styles.phone}>
              <a href="tel:+919928063067" className={styles.phoneLink}>
                9928063067
              </a>
            </div>{" "}
          </div>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person27} alt="Person 1" className={styles.image} />
            <div className={styles.name}>चेतराम मीना</div>
            <div className={styles.post}>कार्यकारिणी सदस्य</div>
            <div className={styles.phone}>
              <a href="tel:+919694968669" className={styles.phoneLink}>
                9694968669
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person28} alt="Person 1" className={styles.image} />
            <div className={styles.name}>जितेंद्र गुर्जर</div>
            <div className={styles.post}>कार्यकारिणी सदस्य</div>
            <div className={styles.phone}>
              <a href="tel:+919602839725" className={styles.phoneLink}>
                9602839725
              </a>
            </div>{" "}
          </div>{" "}
        </div>
      </div>
      {/* Container5 */}

      {/* Container6 */}
      <div className={styles.container}>
        {/* Existing content */}
        <div className={styles.row}>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person29} alt="Person 1" className={styles.image} />
            <div className={styles.name}>कृष्ण कौशिक</div>
            <div className={styles.post}>कार्यकारिणी सदस्य</div>
            <div className={styles.phone}>
              <a href="tel:+917015398403" className={styles.phoneLink}>
                7015398403
              </a>
            </div>
          </div>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person30} alt="Person 1" className={styles.image} />
            <div className={styles.name}>मुकेश कुमार मीना</div>
            <div className={styles.post}>कार्यकारिणी सदस्य</div>
            <div className={styles.phone}>
              <a href="tel:+919001197436" className={styles.phoneLink}>
                9001197436
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person31} alt="Person 1" className={styles.image} />
            <div className={styles.name}>राजेंद्र सिंह शेखावत</div>
            <div className={styles.post}>कार्यकारिणी सदस्य</div>
            <div className={styles.phone}>
              <a href="tel:+919414324571" className={styles.phoneLink}>
                9414324571
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person32} alt="Person 1" className={styles.image} />
            <div className={styles.name}>समदर सिंह राठोड</div>
            <div className={styles.post}>कार्यकारिणी सदस्य</div>
            <div className={styles.phone}>
              <a href="tel:+919799498484" className={styles.phoneLink}>
                9799498484
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person} alt="Person 1" className={styles.image} />
            <div className={styles.name}>रिक्त (सुभाष पारीक)</div>
            <div className={styles.post}>कार्यकारिणी सदस्य</div>
            <div className={styles.phone}>
              {/* <a href="tel:+919001195496" className={styles.phoneLink}>
                9001195496
              </a> */}
            </div>{" "}
          </div>
        </div>
      </div>
      {/* Container6 */}

      {/* Container7 */}
      <div className={styles.container}>
        {/* Existing content */}
        <div className={styles.row}>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person34} alt="Person 1" className={styles.image} />
            <div className={styles.name}>लक्ष्मी</div>
            <div className={styles.post}>कार्यकारिणी सदस्य</div>
          </div>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person} alt="Person 1" className={styles.image} />
            <div className={styles.name}>रिक्त (सारिका जैन)</div>
            <div className={styles.post}>कार्यकारिणी सदस्य</div>
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person36} alt="Person 1" className={styles.image} />
            <div className={styles.name}>श्वेता हैरिस</div>
            <div className={styles.post}>कार्यकारिणी सदस्य</div>
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person37} alt="Person 1" className={styles.image} />
            <div className={styles.name}>विजया व्यास</div>
            <div className={styles.post}>कार्यकारिणी सदस्य</div>
          </div>{" "}
        </div>
      </div>
      {/* Container7 */}
      <hr className={styles.wavyline} />
      {/* Container8 */}
      <div className={styles.container}>
        {/* Existing content */}
        <div className={styles.row}>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person38} alt="Person 1" className={styles.image} />
            <div className={styles.name}>विनीत कुमार मान</div>
            <div className={styles.post}>ज़ोनल शिक्षा निदेशक</div>
            <div className={styles.phone}>
              <a href="tel:+919166642811" className={styles.phoneLink}>
                9166642811
              </a>
            </div>
          </div>
          <div className={styles.column}></div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person39} alt="Person 1" className={styles.image} />
            <div className={styles.name}>पवन जुनेजा</div>
            <div className={styles.post}>CSBF Member</div>
            <div className={styles.phone}>
              <a href="tel:+919001195888" className={styles.phoneLink}>
                9001195888
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person40} alt="Person 1" className={styles.image} />
            <div className={styles.name}>संदीप जानू</div>
            <div className={styles.post}>CSBF Member</div>
            <div className={styles.phone}>
              <a href="tel:+919351920014" className={styles.phoneLink}>
                9351920014
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person41} alt="Person 1" className={styles.image} />
            <div className={styles.name}>खैराजराम भाटी</div>
            <div className={styles.post}>CSBF Member</div>
            <div className={styles.phone}>
              <a href="tel:+918003497593" className={styles.phoneLink}>
                8003497593
              </a>
            </div>{" "}
          </div>{" "}
        </div>
      </div>
      {/* Container8 */}
      <h3 className={styles.centerText}>
        Railway Employees Co-operative Bank - JP (RECB) & The Jacson
        Co-operative Society (JCCS) Director
      </h3>
      {/* Container9 */}
      <div className={styles.container}>
        {/* Existing content */}
        <div className={styles.row}>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person42} alt="Person 1" className={styles.image} />
            <div className={styles.name}>सुनील शर्मा</div>
            <div className={styles.post}>JCCS Ajmer</div>
            <div className={styles.phone}>
              <a href="tel:+919352004591" className={styles.phoneLink}>
                9352004591
              </a>
            </div>
          </div>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person43} alt="Person 1" className={styles.image} />
            <div className={styles.name}>राकेश कुमार यादव</div>
            <div className={styles.post}>JCCS Jaipur</div>
            <div className={styles.phone}>
              <a href="tel:+919001199685" className={styles.phoneLink}>
                9001199685
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person44} alt="Person 1" className={styles.image} />
            <div className={styles.name}>राजेश वर्मा</div>
            <div className={styles.post}>V.P. - RECB</div>
            <div className={styles.phone}>
              <a href="tel:+919001190107" className={styles.phoneLink}>
                9001190107
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person45} alt="Person 1" className={styles.image} />
            <div className={styles.name}>विभोर मिश्रा</div>
            <div className={styles.post}>RECB-Ajmer</div>
            <div className={styles.phone}>
              <a href="tel:+917737564297" className={styles.phoneLink}>
                7737564297
              </a>
            </div>{" "}
          </div>{" "}
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person46} alt="Person 1" className={styles.image} />
            <div className={styles.name}>भावना वर्मा</div>
            <div className={styles.post}>RECB-Ajmer</div>
            <div className={styles.phone}></div>{" "}
          </div>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person47} alt="Person 1" className={styles.image} />
            <div className={styles.name}>सोहन लाल कसाना</div>
            <div className={styles.post}>RECB-Bandikui</div>
            <div className={styles.phone}></div>{" "}
          </div>
          <div className={styles.column}>
            {/* Image, name, and post */}
            <img src={person48} alt="Person 1" className={styles.image} />
            <div className={styles.name}>देशराज सिंह</div>
            <div className={styles.post}>RECB-Jaipur</div>
            <div className={styles.phone}>
              <a href="tel:+919772445533" className={styles.phoneLink}>
                9772445533
              </a>
            </div>{" "}
          </div>
        </div>
      </div>
      {/* Container9 */}
      <div className={styles.padadhikari}>
        <br></br>
        <u>
          <h1>- पदाधिकारी -</h1>
        </u>
        <ul className={styles.padadhikariList}>
          <Link to="/AjmerDivision">
            <li>
              <h2>अजमेर मण्डल</h2>
            </li>
          </Link>
          <Link to="/JaipurDivision">
            <li>
              <h2>जयपुर मण्डल</h2>
            </li>
          </Link>
          <li>
            <Link to="/HQWorkshop">
              <h2>कारख़ाना व प्रधान कार्यालय</h2>
            </Link>
          </li>
          <Link to="/BikanerDivision">
            <li>
              <h2>बीकानेर मण्डल</h2>
            </li>
          </Link>
          <Link to="/JodhpurDivision">
            <li>
              <h2>जोधपुर मण्डल</h2>
            </li>
          </Link>
        </ul>
        <Link to="/OtherRail">
          <h2>अन्य रेलवे</h2>
        </Link>
      </div>
    </div>
  );
}

export default Organisation;

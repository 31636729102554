// src/components/Homepage.js
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import styles from "./Homepage.module.css";
import person1 from "../../assets/P1.jpeg";
import person2 from "../../assets/P2.jpeg";
import person3 from "../../assets/P3.jpeg";
import person4 from "../../assets/P4.jpeg";
import person5 from "../../assets/P5.jpeg";
import person6 from "../../assets/P6.jpeg";
import slider1 from "../../assets/images/Banner(1).jpeg";
import slider2 from "../../assets/images/Banner(1).jpg";
import slider3 from "../../assets/images/Banner (2).jpg";
import slider4 from "../../assets/images/Banner (3).jpg";
import slider5 from "../../assets/images/Banner (4).jpg";
import slider6 from "../../assets/images/Banner (5).jpg";
import slider7 from "../../assets/images/Banner (6).jpg";
import slider8 from "../../assets/images/Banner (7).jpg";
import slider9 from "../../assets/images/Banner (8).jpg";
import slider10 from "../../assets/images/Banner (9).jpg";
import slider11 from "../../assets/images/Banner (10).jpg";
import slider12 from "../../assets/images/Banner (11).jpg";
import slider13 from "../../assets/images/Banner (12).jpg";
import slider14 from "../../assets/images/Banner (13).jpg";
import slider15 from "../../assets/images/Banner (14).jpg";
import slider16 from "../../assets/images/Banner (15).jpg";
import slider17 from "../../assets/images/Banner (16).jpg";
import slider18 from "../../assets/images/Banner (17).jpg";
import slider19 from "../../assets/images/Banner (18).jpg";
import slider20 from "../../assets/images/Banner (19).jpg";
import slider21 from "../../assets/images/Banner (20).jpg";
import slider22 from "../../assets/images/Banner (21).jpg";
import slider23 from "../../assets/images/Banner (22).jpg";
import slider24 from "../../assets/images/Banner (23).jpg";
import slider25 from "../../assets/images/Banner (2).jpeg";
import slider26 from "../../assets/images/Banner (3).jpeg";
import slider27 from "../../assets/images/Banner (4).jpeg";
import slider28 from "../../assets/images/Banner (5).jpeg";
import slider29 from "../../assets/images/Banner (6).jpeg";
import slider30 from "../../assets/images/Banner (7).jpeg";
import slider31 from "../../assets/images/Banner (8).jpeg";
import slider32 from "../../assets/images/Banner (9).jpeg";
import slider33 from "../../assets/images/Banner (10).jpeg";
import slider34 from "../../assets/images/Banner (11).jpeg";
import slider35 from "../../assets/images/Banner (12).jpeg";
import slider36 from "../../assets/images/Banner (13).jpeg";
import slider37 from "../../assets/images/Banner (14).jpeg";
import slider38 from "../../assets/images/Banner (15).jpeg";
import slider39 from "../../assets/images/Banner (16).jpeg";
import slider40 from "../../assets/images/Banner (17).jpeg";
import slider41 from "../../assets/images/Banner (18).jpeg";
import slider42 from "../../assets/images/Banner (19).jpeg";
import slider43 from "../../assets/images/Banner (20).jpeg";
import slider44 from "../../assets/images/NWREU July2024 (1).jpeg";
import slider45 from "../../assets/images/NWREU July2024 (1).jpeg";
import slider46 from "../../assets/images/NWREU July2024 (1).jpg";
import slider47 from "../../assets/images/NWREU July2024 (2).jpeg";
import slider48 from "../../assets/images/NWREU July2024 (2).jpg";
import slider49 from "../../assets/images/NWREU July2024 (3).jpeg";
import slider50 from "../../assets/images/NWREU July2024 (3).jpg";
import slider51 from "../../assets/images/NWREU July2024 (4).jpeg";
import slider52 from "../../assets/images/NWREU July2024 (4).jpg";
import slider53 from "../../assets/images/NWREU July2024 (5).jpeg";
import slider54 from "../../assets/images/NWREU July2024 (5).jpg";
import slider55 from "../../assets/images/NWREU July2024 (6).jpeg";
import slider56 from "../../assets/images/NWREU July2024 (7).jpeg";
import slider57 from "../../assets/images/NWREU July2024 (8).jpeg";
import slider58 from "../../assets/images/NWREU July2024 (9).jpeg";
import slider59 from "../../assets/images/NWREU July2024 (10).jpeg";
import slider60 from "../../assets/images/NWREU July2024 (11).jpeg";
import slider61 from "../../assets/images/NWREU July2024 (12).jpeg";
import slider62 from "../../assets/images/NWREU July2024 (13).jpeg";
import slider63 from "../../assets/images/NWREU July2024 (14).jpeg";
import slider64 from "../../assets/images/NWREU July2024 (15).jpeg";
import slider65 from "../../assets/images/NWREU July2024 (16).jpeg";
import slider66 from "../../assets/images/NWREU July2024 (17).jpeg";
import slider67 from "../../assets/images/NWREU July2024 (18).jpeg";
import slider68 from "../../assets/images/NWREU July2024 (19).jpeg";
import slider69 from "../../assets/images/NWREU July2024 (20).jpeg";
import slider70 from "../../assets/images/NWREU July2024 (21).jpeg";
import slider71 from "../../assets/images/NWREU July2024 (22).jpeg";

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import poster from "../../assets/poster.jpeg";

function Homepage({ hasBanner }) {
  const images = [
    slider1,
    slider2,
    slider3,
    slider4,
    slider5,
    slider6,
    slider7,
    slider8,
    slider9,
    slider10,
    slider11,
    slider12,
    slider13,
    slider14,
    slider15,
    slider16,
    slider17,
    slider18,
    slider19,
    slider20,
    slider21,
    slider22,
    slider23,
    slider24,
    slider25,
    slider26,
    slider27,
    slider28,
    slider29,
    slider30,
    slider31,
    slider32,
    slider33,
    slider34,
    slider35,
    slider36,
    slider37,
    slider38,
    slider39,
    slider40,
    slider41,
    slider42,
    slider43,
    slider44,
    slider45,
    slider46,
    slider47,
    slider48,
    slider49,
    slider50,
    slider51,
    slider52,
    slider53,
    slider54,
    slider55,
    slider56,
    slider57,
    slider58,
    slider59,
    slider60,
    slider61,
    slider62,
    slider63,
    slider64,
    slider65,
    slider66,
    slider67,
    slider68,
    slider69,
    slider70,
    slider71,
  ];

  // Shuffle the images array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const shuffledImages = shuffleArray([...images]); // Create a shuffled copy of images array

  const [currentIndex, setCurrentIndex] = useState(
    Math.floor(Math.random() * shuffledImages.length)
  );

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? shuffledImages.length - 1 : prevIndex - 1
    );
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === shuffledImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const startSliding = () => {
    setInterval(() => {
      nextImage();
    }, 7000);
  };

  useEffect(() => {
    startSliding();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.gridItem}>
          <img src={person1} alt="Person 1" className={styles.image} />
        </div>
        <div className={styles.gridItem}>
          <img src={person2} alt="Person 2" className={styles.image} />
        </div>
        <div className={styles.gridItem}>
          <img src={person3} alt="Person 3" className={styles.image} />
        </div>
        <div className={styles.gridItem}>
          <img src={person4} alt="Person 4" className={styles.image} />
        </div>
        <div className={styles.gridItem}>
          <img src={person5} alt="Person 5" className={styles.image} />
        </div>
        <div className={styles.gridItem}>
          <img src={person6} alt="Person 6" className={styles.image} />
        </div>

        <div className={styles.gridItem}>
          <div className={styles.name}>Dr. N Kannaiah</div>
        </div>
        <div className={styles.gridItem}>
          <div className={styles.name}>Manoj Kumar Parihar</div>
        </div>
        <div className={styles.gridItem}>
          <div className={styles.name}>Shiv Gopal Mishra</div>
        </div>
        <div className={styles.gridItem}>
          <div className={styles.name}>Mukesh Mathur</div>
        </div>
        <div className={styles.gridItem}>
          <div className={styles.name}>JR Bhosale</div>
        </div>
        <div className={styles.gridItem}>
          <div className={styles.name}>Mukesh Chaturvedi</div>
        </div>

        <div className={styles.gridItem}>
          <div className={styles.post}>
            President
            <br /> AIRF
          </div>
        </div>
        <div className={styles.gridItem}>
          <div className={styles.post}>
            President
            <br /> NWREU
          </div>
        </div>
        <div className={styles.gridItem}>
          <div className={styles.post}>
            General Secretary <br /> AIRF
          </div>
        </div>
        <div className={styles.gridItem}>
          <div className={styles.post}>
            General Secretary
            <br /> NWREU
          </div>
        </div>
        <div className={styles.gridItem}>
          <div className={styles.post}>
            Working President <br /> AIRF
          </div>
        </div>
        <div className={styles.gridItem}>
          <div className={styles.post}>
            Working President
            <br /> NWREU
          </div>
        </div>
      </div>

      {/* <Link to="/KnowYourBooth">
        <button className={styles.knowBoothBtn}>KNOW YOUR BOOTH</button>
      </Link> */}

      {/* Optional image banner */}
      {hasBanner && <img src={poster} alt="Banner" className={styles.banner} />}

      {/* Image slider */}
      <div className={styles.carousel}>
        <button className={styles.prevBtn} onClick={prevImage}>
          <FaChevronLeft className={styles.icon} />
        </button>{" "}
        <img
          src={shuffledImages[currentIndex]}
          alt={`Image ${currentIndex + 1}`}
          className={styles.carouselImage}
        />
        <button className={styles.nextBtn} onClick={nextImage}>
          <FaChevronRight className={styles.icon} />
        </button>{" "}
      </div>
    </div>
  );
}

export default Homepage;
